<template>
  <div class="lookBack">
    <Header :active="this.$route.query.navActive || 'lookBack'" />
    <Video :lookBack="'lookBack'" />
  </div>
</template>

<script>
import Header from "@/components/header";
import Video from "./components/video.vue";
export default {
  name: "lookBack",
  components: {
    Header,
    Video,
  },
  data() {
    return {
      navActive: 'lookBack'
    };
  },
  mounted() {
  },
};
</script>

<style lang="less" scoped>
.lookBack {
  padding: 135px 0;
  background: #005aa4;
  box-sizing: border-box;
}
.flex{
  position: fixed;
  top: 100px;
  left: 100px;
  z-index: 9999;
}
</style>